export const LANGUAGE = {
	sucursal: {
		ES: {
			slideTitle: 'Tu opinión es importante',
			question1: '¿Cómo fue tu experiencia de servicio con Seguros Múltiples?',
			question2: '¿Cómo nos calificas en estas áreas?',
			area1: 'Amabilidad y cortesía',
			area2: 'Conocimiento del personal',
			area3: 'Tiempo de espera',
			area4: 'Orientación brindada',
			question3: '¿Que pudiéramos hacer para mejorar tu próxima experiencia de servicio?',
			question4: '¿Porqué asignó esta puntuación?',
			reasonQuestion: 'Seleccione el motivo principal.',
			sendButton: 'Enviar',
			continueButton: 'Continuar',
			textLoading: 'Favor Esperar',
			textSurveyError: 'Favor seleccionar una respuesta',
			textReasonsError: 'Favor seleccionar un motivo',
			textSurveySuccess: 'Encuesta enviada',
			textFinish: '¡Agradecemos tu opinión, la cual nos permite seguir mejorando nuestro servicio!',
			reasonsList: [
				{ key: 'SELECT', value: 'Selecciona' },
				{ key: 'WAIT_TIME', value: 'Tiempo de Espera' },
				{ key: 'COMMUNICATION_ADJUSTER', value: 'Comunicación con el ajustador' },
				{ key: 'PAYMENT_CLAIM', value: 'Desacuerdo con el pago de reclamación' },
				{ key: 'CALLS_NOT_ANSWERED', value: 'No responden llamadas' },
				{ key: 'INAPPROPRIATE_GUIDANCE', value: 'Orientación inadecuada' },
				{ key: 'LACK_INTEREST_SOLVING', value: 'Falta de interés en resolver' },
			],
			textFinish2: 'Indíquenos cuál es su canal de preferencia para tramitar nuestros servicios:',
			option1: 'Página de Internet',
			option2: 'Aplicación móvil',
			option3: 'Llamada',
			option4: 'Visita a sucursal',
			option5: 'Representante Autorizado Exclusivo',
		},
		EN: {
			slideTitle: 'Your opinion is important',
			question1: 'How was your service experience with Seguros Múltiples?',
			question2: 'How do you rate us in these areas?',
			area1: 'Kindness and courtesy',
			area2: 'Staff knowledge',
			area3: 'Waiting time',
			area4: 'Guidance provided',
			question3: 'What could we do to improve your next service experience?',
			question4: 'Why did you assign this score?',
			reasonQuestion: 'Select the main reason.',
			sendButton: 'Send',
			continueButton: 'Continue',
			textLoading: 'Please wait',
			textSurveyError: 'Please select an answer',
			textReasonsError: 'Please select a reason',
			textSurveySuccess: 'Survey sent',
			textFinish: 'We appreciate your opinion, which allows us to continue improving our service!',
			reasonsList: [
				{ key: 'SELECT', value: 'Select' },
				{ key: 'WAIT_TIME', value: 'Wait time' },
				{ key: 'COMMUNICATION_ADJUSTER', value: 'Communication with the adjuster' },
				{ key: 'PAYMENT_CLAIM', value: 'Payment of the claim' },
				{ key: 'CALLS_NOT_ANSWERED', value: 'Calls are not answered' },
				{ key: 'INAPPROPRIATE_GUIDANCE', value: 'Inappropriate guidance' },
				{ key: 'LACK_INTEREST_SOLVING', value: 'Lack of interest in solving' },
			],
			textFinish2: 'Once the pandemic passes, how would you prefer to receive the services we offer?',
			option1: 'Internet page',
			option2: 'Mobile app',
			option3: 'By phone',
			option4: 'By visiting a Branch',
			option5: 'Exclusive Authorized Representative'
		},
	},
	tele: {
		ES: {
			slideTitle: 'Tu opinión es importante',
			question1: '¿Cómo fue tu experiencia de servicio con TeleSeguros Múltiples?',
			question2: '¿Cómo nos calificas en estas áreas?',
			area1: 'Amabilidad y cortesía',
			area2: 'Conocimiento del personal',
			area3: 'Tiempo de espera',
			area4: 'Orientación brindada',
			question3: '¿Que pudiéramos hacer para mejorar tu próxima experiencia de servicio?',
			question4: '¿Porqué asignó esta puntuación?',
			reasonQuestion: 'Seleccione el motivo principal.',
			sendButton: 'Enviar',
			continueButton: 'Continuar',
			textLoading: 'Favor Esperar',
			textSurveyError: 'Favor seleccionar una respuesta',
			textReasonsError: 'Favor seleccione un motivo',
			textSurveySuccess: 'Encuesta enviada',
			textFinish: '¡Agradecemos tu opinión, la cual nos permite seguir mejorando nuestro servicio!',
			reasonsList: [
				{ key: 'SELECT', value: 'Selecciona' },
				{ key: 'WAIT_TIME', value: 'Tiempo de Espera' },
				{ key: 'COMMUNICATION_ADJUSTER', value: 'Comunicación con el ajustador' },
				{ key: 'PAYMENT_CLAIM', value: 'Desacuerdo con el pago de reclamación' },
				{ key: 'CALLS_NOT_ANSWERED', value: 'No responden llamadas' },
				{ key: 'INAPPROPRIATE_GUIDANCE', value: 'Orientación inadecuada' },
				{ key: 'LACK_INTEREST_SOLVING', value: 'Falta de interés en resolver' },
			],
			textFinish2: 'Indíquenos cuál es su canal de preferencia para tramitar nuestros servicios:',
			option1: 'Página de Internet',
			option2: 'Aplicación móvil',
			option3: 'Llamada',
			option4: 'Visita a sucursal',
			option5: 'Representante Autorizado Exclusivo',
		},
		EN: {
			slideTitle: 'Your opinion is important',
			question1: 'How was your service experience with TeleSeguros Múltiples?',
			question2: 'How do you rate us in these areas?',
			area1: 'Kindness and courtesy',
			area2: 'Staff knowledge',
			area3: 'Waiting time',
			area4: 'Guidance provided',
			question3: 'What could we do to improve your next service experience?',
			question4: 'Why did you assign this score?',
			reasonQuestion: 'Select the main reason.',
			sendButton: 'Send',
			continueButton: 'Continue',
			textLoading: 'Please wait',
			textSurveyError: 'Please select an answer',
			textReasonsError: 'Please select a reason',
			textSurveySuccess: 'Survey sent',
			textFinish: 'We appreciate your opinion, which allows us to continue improving our service!',
			reasonsList: [
				{ key: 'SELECT', value: 'Select' },
				{ key: 'WAIT_TIME', value: 'Wait time' },
				{ key: 'COMMUNICATION_ADJUSTER', value: 'Communication with the adjuster' },
				{ key: 'PAYMENT_CLAIM', value: 'Payment of the claim' },
				{ key: 'CALLS_NOT_ANSWERED', value: 'Calls are not answered' },
				{ key: 'INAPPROPRIATE_GUIDANCE', value: 'Inappropriate guidance' },
				{ key: 'LACK_INTEREST_SOLVING', value: 'Lack of interest in solving' },
			],
			textFinish2: 'Once the pandemic passes, how would you prefer to receive the services we offer?',
			option1: 'Internet page',
			option2: 'Mobile app',
			option3: 'By phone',
			option4: 'By visiting a Branch',
			option5: 'Exclusive Authorized Representative'
		},
	},
};
